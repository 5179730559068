export const getNeto = ({
  value,
  tasaIsr = 10,
  tasaIvaR = 10.66,
  tasaIvaT = 16,
}) => {
  const isr = tasaIsr / 100;
  const ivaR = tasaIvaR / 100;
  const ivaT = tasaIvaT / 100 + 1;

  const x = (value / (1 - isr - ivaR)) * (1 / ivaT);
  return x;
};

const Quiero = ({
  value,
  tasaIsr = 10,
  tasaIvaR = 10.66,
  tasaIvaT = 16,
}) => {
  const neto = getNeto({ value, tasaIsr, tasaIvaR, tasaIvaT });
  const ivaT = neto * (tasaIvaT / 100);
  const subtotal = neto + ivaT;
  const ivaR = subtotal * (tasaIvaR / 100);
  const isr = subtotal * (tasaIsr / 100);
  const total = subtotal - ivaR - isr;

  return {
    neto: neto.toFixed(2),
    ivaT: ivaT.toFixed(2),
    subtotal: subtotal.toFixed(2),
    ivaR: ivaR.toFixed(2),
    isr: isr.toFixed(2),
    total: total.toFixed(2),
  };
};

export default Quiero;
