import { useEffect } from 'react';

export const MODES = ['quiero', 'tengo'];

export const format = value =>
  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function useKeyPress({ targetKey, onDown = () => {}, onUp = () => {} }) {
  useEffect(() => {
    function downHandler(e) {
      const { key } = e;
      if (key === targetKey) {
        onDown();
        e.preventDefault();
      }
    }

    function upHandler(e) {
      const { key } = e;
      if (key === targetKey) {
        onUp();
        e.preventDefault();
      }
    }

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [onDown, onUp, targetKey]); // Empty array ensures that effect is only run on mount and unmount
}
