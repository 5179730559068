export const getNeto = ({ value, liquidPorcentage = 95.34 }) => {
  const resultado = value / liquidPorcentage;
  const neto = resultado * 100;
  return neto;
};

const Tengo = ({
  value,
  tasaIsr = 10,
  tasaIvaR = 10.66,
  tasaIvaT = 16,
  liquidPorcentage = 95.34,
}) => {
  const neto = getNeto({ value, liquidPorcentage });

  const ivaT = neto * (tasaIvaT / 100);
  const subtotal = neto + ivaT;

  const isr = neto * (tasaIsr / 100);
  const ivaR = neto * (tasaIvaR / 100);

  const total = subtotal - (ivaR + isr);

  return {
    neto: neto.toFixed(2),
    ivaT: ivaT.toFixed(2),
    subtotal: subtotal.toFixed(2),
    ivaR: ivaR.toFixed(2),
    isr: isr.toFixed(2),
    total: total.toFixed(2),
  };
};

export default Tengo;
