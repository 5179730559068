import React from 'react';
import { format } from '../utils';

import InputNumber from 'rc-input-number';
import Input from '../input-w-clipboard';

import './style.css';

const Form = ({ value, setValue, getValues, inputEl, options }) => {
  const { neto, ivaT, subtotal, ivaR, isr, total } = getValues({
    value,
    ...options,
  });

  return (
    <div className="wrapper -box">
      <section>
        <div className="input-w-clipboard">
          <label htmlFor="value">cantidad</label>
          <InputNumber
            min={0}
            ref={inputEl}
            useTouch
            name="value"
            value={value}
            onChange={setValue}
            formatter={format}
            autoFocus
          />
        </div>
      </section>

      <section>
        <Input label="honorarios" value={neto} />
        <Input label="iva ( t )" value={ivaT} />
      </section>
      <section>
        <Input label="subtotal" value={subtotal} />
      </section>
      <section>
        <Input label="isr ( r )" value={isr} />
        <Input label="iva ( r )" value={ivaR} />
      </section>
      <section>
        <Input label="total" value={total} />
      </section>
    </div>
  );
};

export default Form;
