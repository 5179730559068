import React from 'react';
import ReactMarkdown from 'react-markdown';

import './style.css';

const text = `
### ¿Qué cambia entre Tengo y Quiero?
En *QUIERO* si quieres cierta cantidad neta, deberías de pedir la cantidad que dice en honorarios.

En *TENGO* la idea es que si ya te pagaron con eso llenas tus facturas del *SAT*.

*HONORARIOS* es el concepto de tu factura.

---

### ¿Por qué cambian los valores entre QUIERO y TENGO ?

Se calculan de manera diferente, probablemente la sección de *QUIERO* está mal, no soy contador.

Si te interesa checar como se calcula puedes ver el [código fuente](https://gitlab.com/afk_mario/factura).
`;

const Info = () => {
  return (
    <section className="info rich-text">
      <ReactMarkdown source={text}></ReactMarkdown>
    </section>
  );
};

export default Info;
