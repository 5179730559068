import React from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { format } from '../utils';
import { ReactComponent as Clipboard } from '../icons/clipboard.svg';

import './style.css';

const InputWClipboard = ({ label, name, value }) => {
  const clipboard = useClipboard();
  return (
    <button className="input-w-clipboard" onClick={clipboard.copy}>
      <label htmlFor={name}>{label}</label>
      <input
        style={{ display: 'none' }}
        name={name}
        ref={clipboard.target}
        value={value}
        readOnly={true}
      />
      <span className="value">{format(value)}</span>
      <Clipboard />
    </button>
  );
};

export default InputWClipboard;
