import React from 'react';
import { ReactComponent as Gear } from '../icons/gear.svg';

import './style.css';

const Footer = ({ showOptions, toggleOptions }) => {
  return (
    <footer>
      <div className="wrapper -box">
        <button
          className={`${showOptions ? '-active' : ''}`}
          onClick={toggleOptions}
        >
          <Gear />
        </button>
        <span>Hecho por</span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://twitter.com/afk_mario"
        >
          @afk_mario
        </a>
        <span> - </span>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://gitlab.com/afk_mario/factura/issues"
        >
          ¿issues?
        </a>
      </div>
    </footer>
  );
};

export default Footer;
