import React, { useState, useRef } from 'react';
import { useKeyPress } from '../utils';

// import 'rc-input-number/assets/index.css';
import './variables.css';
import './style.css';

import { MODES } from '../utils';
import Quiero from '../quiero';
import Tengo from '../tengo';

import Header from '../header';
import Footer from '../footer';
import Options from '../options';
import Form from '../form';

const App = () => {
  const [mode, setMode] = useState(MODES[1]);
  const [value, setValue] = useState(1000);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState({
    tasaIsr: 10,
    tasaIvaR: 10.66,
    tasaIvaT: 16,
    liquidPorcentage: 95.34,
  });

  const inputEl = useRef(null);
  const quiero = mode === MODES[0];
  const nMode = quiero ? MODES[1] : MODES[0];
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  useKeyPress({
    targetKey: ' ',
    onUp: () => {
      setMode(nMode);
      inputEl.current.focus();
    },
  });

  useKeyPress({
    targetKey: 'o',
    onUp: () => {
      setShowOptions(!showOptions);
    },
  });

  useKeyPress({
    targetKey: 'Escape',
    onUp: () => {
      setShowOptions(false);
    },
  });

  const getValues = mode === MODES[0] ? Quiero : Tengo;

  return (
    <div className="app">
      <Header mode={mode} setMode={setMode} inputEl={inputEl} />
      <Form
        value={value}
        setValue={setValue}
        getValues={getValues}
        inputEl={inputEl}
        options={options}
      />
      {showOptions ? (
        <Options
          options={options}
          setOptions={value => setOptions({ ...options, ...value })}
          setShowOptions={setShowOptions}
        />
      ) : null}
      <Footer showOptions={showOptions} toggleOptions={toggleOptions} />
    </div>
  );
};

export default App;
