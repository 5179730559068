import React from 'react';

import InputNumber from 'rc-input-number';

import './style.css';

const Input = ({ name, label, value, setValue, formatter }) => {
  return (
    <div className="input-w-clipboard">
      <label htmlFor={name}>{label}</label>
      <div className="input-wrapper">
        <InputNumber
          useTouch
          min={0}
          formatter={formatter}
          name={name}
          value={value}
          onChange={value => {
            setValue({ [name]: value });
          }}
        />
      </div>
    </div>
  );
};

export default Input;
