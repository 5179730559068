import React from 'react';
import { MODES } from '../utils';

import { ReactComponent as Swap } from '../icons/swap.svg';

import './style.css';

const Header = ({ setMode, mode, inputEl }) => {
  const quiero = mode === MODES[0];
  const nMode = quiero ? MODES[1] : MODES[0];
  return (
    <header>
      <div className="wrapper -box">
        <button
          onClick={() => {
            setMode(nMode);
            inputEl.current.focus();
          }}
        >
          <h1>
            <span className={quiero ? '-active' : null}>QUIERO</span>
            <Swap className={`arrows ${quiero ? '-right' : '-left'}`} />
            <span className={!quiero ? '-active' : null}>TENGO</span>
          </h1>
        </button>
      </div>
    </header>
  );
};

export default Header;
