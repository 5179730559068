import React from 'react';
import packageJson from '../../package.json';

import Input from '../input';
import Info from '../info';

import './style.css';

const Form = ({ options, setOptions, setShowOptions }) => {
  const { tasaIsr, tasaIvaR, tasaIvaT, liquidPorcentage } = options;

  return (
    <div className="options">
      <div
        className="options-bg"
        onClick={e => {
          e.preventDefault();
          setShowOptions(false);
        }}
      ></div>
      <div className="wrapper -box">
        <section>
          <h2>
            <span>Opciones</span>
            <span className="-mono">v.{packageJson.version}</span>
          </h2>
        </section>
        <Info />
        <section>
          <Input
            name="tasaIsr"
            label="Tasa ISR Retenido"
            value={tasaIsr}
            setValue={setOptions}
            formatter={value => `${value}%`}
          />
          <Input
            name="tasaIvaR"
            label="Tasa IVA Retenido"
            value={tasaIvaR}
            setValue={setOptions}
            formatter={value => `${value}%`}
          />
        </section>
        <section>
          <Input
            name="tasaIvaT"
            label="Tasa IVA Trasladado"
            value={tasaIvaT}
            setValue={setOptions}
            formatter={value => `${value}%`}
          />
          <Input
            name="liquidPorcentage"
            label="Porcentaje liquido"
            value={liquidPorcentage}
            setValue={setOptions}
            formatter={value => `${value}%`}
          />
        </section>
      </div>
    </div>
  );
};

export default Form;
